import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logoMain from "../images/logo-big-white.png";
import logoMainDark from "../images/logo-big-black.png";
import logoMainMobile from "../images/logo-short-big-white.png";
import logoMainDarkMobile from "../images/logo-short-big-black.png";

import { BrowserView, MobileView } from "react-device-detect";

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const Nav = styled.nav`
  display: flex;
  gap: 20px;
  align-items: baseline;
`;

const NavLink = styled(Link)`
  font-size: 16px;
  font-weight: bold;
  transition: color 0.3s;
  &:hover {
    color: #00a1ec;
  }
`;

const ToogleThemeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ToggleCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ToggleCheckbox = styled.input`
  appearance: none;
  width: 40px;
  height: 20px;
  background: ${({ theme }) => theme.buttonBackground};
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;

  &:checked {
    background-color: ${({ theme }) => theme.buttonHoverBackground};
  }

  &::before {
    content: "";
    position: absolute;
    top: 35%;
    left: 1px;
    width: 12px;
    height: 12px;
    background: transparent;
    border-radius: 50%;
    transition: transform 0.3s;
    transform: translateY(-50%);
    box-shadow: 3px 3px 0 0;
  }

  &:checked::before {
    transform: translate(20px, -50%);
    box-shadow: none;
    background: ${({ theme }) => theme.buttonText};
    top: 50%;
    left: 4px;
  }
`;

const Label = styled.label`
  font-size: 7px;
  font-weight: bold;
  cursor: pointer;
`;

const Logo = styled.img`
  width: 15%;
  height: auto;
`;

const LogoMobile = styled.img`
  width: 60%;
  max-width: 200px;
  height: auto;
`;

const Header = ({ theme, toggleTheme }) => {
  const isDarkMode = theme === "dark";
  const isLightMode = theme === "light";
  const logoSource = theme === "dark" ? logoMain : logoMainDark;
  const logoSourceMobile =
    theme === "dark" ? logoMainMobile : logoMainDarkMobile;

  return (
    <HeaderContainer>
      <BrowserView>
        <Logo src={logoSource} alt="Goncalo Coelho" />
      </BrowserView>
      <MobileView>
        <LogoMobile src={logoSourceMobile} alt="Goncalo Coelho" />
      </MobileView>
      <Nav>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/UnderConstruction">Path</NavLink>
        <NavLink to="/UnderConstruction">Contacts</NavLink>
        <ToogleThemeContainer>
          <ToggleCheckboxContainer>
            <ToggleCheckbox
              type="checkbox"
              checked={isLightMode}
              onChange={toggleTheme}
            />
          </ToggleCheckboxContainer>
          <Label>{isDarkMode ? "Dark Mode" : "Light Mode"}</Label>
        </ToogleThemeContainer>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
