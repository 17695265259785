import React from "react";
import styled from "styled-components";

const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
  transition: color 0.3s;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const Button = styled.a`
  background-color: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.buttonText};
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.buttonHoverBackground};
  }
`;

const Main = () => {
  return (
    <MainContainer>
      <Title>Sorry, this page is under construction</Title>
      <Button href="/">Home</Button>
    </MainContainer>
  );
};

export default Main;
