import React from 'react';
import Main from '../components/Main';
import styled from 'styled-components';

const MainContainer = styled.main`
  background-position: center;
`;

const Home = () => {
  return (
    <MainContainer>
      <Main />
    </MainContainer>
  );
};

export default Home;
