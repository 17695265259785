import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./GlobalStyles";
import Header from "./components/Header";
import Home from "./pages/Home";
import Path from "./pages/Path";
import Contact from "./pages/Contact";
import UnderConstruction from "./pages/UnderConstruction";
import { lightTheme, darkTheme } from "./themes/theme";

function App() {
  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <GlobalStyle />
      <Router>
        <Header theme={theme} toggleTheme={toggleTheme} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/path" element={<Path />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/UnderConstruction" element={<UnderConstruction />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
