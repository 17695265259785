import backgroundImage from '../images/background-main.jpg';
import backgroundImageDark from '../images/background-main-dark.jpg';



export const lightTheme = {
    body: '#FFF',
    text: '#333',
    toggleBorder: '#FFF',
    background: '#C3EBF9',
    buttonBackground: '#CBB373',
    buttonText: '#333',
    buttonHoverBackground: '#E1B642',
    backgroundImage: `url(${backgroundImage})`
  };
  
  export const darkTheme = {
    body: '#333',
    text: '#FFF',
    toggleBorder: '#6B8096',
    background: '#333',
    buttonBackground: '#00a1ec',
    buttonText: '#FFF',
    buttonHoverBackground: '#C3EBF9',
    backgroundImage: `url(${backgroundImageDark})`
  };
  