import React from "react";
import styled from "styled-components";
import profilePhoto from "../images/profile-photo.jpg";

const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
  transition: color 0.3s;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.text};
  max-width: 70%;
  transition: color 0.3s;

  @media (max-width: 768px) {
    font-size: 16px;
    max-width: 100%;
  }
`;

const Button = styled.a`
  background-color: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.buttonText};
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.buttonHoverBackground};
  }
`;

const ProfilePhoto = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    width: 150px;
    max-height: 150px;
  }
`;

const Photo = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 50%;
`;

const Main = () => {
  return (
    <MainContainer>
      <ProfilePhoto>
        <Photo src={profilePhoto} alt="Goncalo Coelho" />
      </ProfilePhoto>
      <Title>Hello, I'm Goncalo Coelho</Title>
      <Subtitle>
        Born in 1991 in Portugal, I studied Computer Science at IPT Tomar. Since
        starting my career as a Frontend Engineer in 2018 at a leading gambling
        company, I've gained experience across multiple technologies, including
        Marionette, ExtJS, React, and Vue.
        <br />
        <br />
        My passion lies in building clean, efficient, and user-focused websites,
        and I enjoy working with modern frameworks to create seamless digital
        experiences.
        <br />
        <br />
        Let's collaborate and turn great ideas into functional, engaging
        interfaces!
        <br />
      </Subtitle>
      <Button href="/UnderConstruction">the path</Button>
    </MainContainer>
  );
};

export default Main;
